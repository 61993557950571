<template>
  <div>
    <div class="container mx-auto h-auto laptop:h-almostscreen flex-col flex md:justify-between laptop:justify-center" style="min-height: 550px;">
      <div class="z-20 p-1 laptop:ml-12 fullhd:ml-0 px-5 md:p-5 laptop:p-0 pointer-events-none">
        <MARSHeading><div v-html="$t('home.hero').toString()" /></MARSHeading>
        <ActionButton :href="panelLinks.registerUrl.value" :value="$t('home.free-trial').toString()" :bg="Colors.orange" :fg="Colors.white" />
      </div>
      <div class="w-full h-iframeContainer md:h-iframeContainerMd laptop:absolute laptop:right-0 laptop:w-2/5 laptop:h-almostscreen z-10 -mt-16 lg:mt-0" style="min-height: 550px;">
        <iframe
          style="width:100%; height:100%;"
          allow="xr-spatial-tracking *; fullscreen"
          :src="'https://viewer.myarstudio.cloud/viewer_master/index.html?p=86d6b5d1-ed63-5a2d-8018-f214d5c3fc41&c=85af7464-2fe9-5eda-9368-816c131bd574&b=f2f01834-6984-4e4d-bd20-8e7586523cf0&language=' + $i18n.locale"
        />
      </div>
      <div class="w-16 h-16 hidden laptop:block absolute bottom-0 mb-6 left-1/2 -ml-8 z-20">
        <img loading="lazy" class="mx-auto" src="~/assets/img/scroll-down-gif.gif" alt="Scroll down indicator">
      </div>
    </div>

    <div
      class="w-full image-discover bg-visualizer-small md:bg-visualizer-medium laptop:bg-visualizer bg-center bg-cover"
    >
      <div class="container h-full mx-auto">
        <div class="flex flex-col justify-around h-full p-5 laptop:ml-24 md:ml-8">
          <div class="xl:w-9/12 w-9/12">
            <h2 class="text-white text-lg laptop:text-45xl md:text-2xl md:w-3/4">
              {{ $t("home.cta-1") }}
            </h2>
            <div class="hidden md:block">
              <!-- FIXME: if route property is set, the class attribute is not passed to the rendered html 
                    using the class on parent but this may lead to unexpected behavior -->
              <ActionButton route="viewer-3d-ar" class="hidden md:block" :value="$t('home.discover').toString()" bg="highlight" fg="white" />
            </div>
          </div>
          <div class="block md:hidden">
            <ActionButton route="viewer-3d-ar" :value="$t('home.discover').toString()" bg="highlight" fg="white" />
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto flex flex-col laptop:flex-row laptop:mt-150px pt-10 md:pt-16">
      <div class="laptop:w-5/12 p-5 md:p-0 fullhd:pl-5 fullhd:pr-65px laptop:mx-24 md:mx-8 md:w-5/6">
        <MARSTitle><div v-html="$t('home.sell-more').toString()" /></MARSTitle>
        <p class="text-standard laptop:text-slg md:text-base leading-7">{{ $t('home.sell-more-description-1').toString() }}</p>
        <ActionButton class="lg:-mt-16" :href="panelLinks.registerUrl.value" :value="$t('home.free-trial').toString()" bg="orange" fg="white" />
      </div>
      <div class="laptop:w-5/12 p-5 md:mt-12 laptop:mt-0 laptop:pr-70px md:p-0 md:mx-8 laptop:p-0 md:w-5/6">
        <div class="my-0 laptop:text-slg md:text-base leading-7" v-html="$t('home.sell-more-description-2').toString()" />
      </div>
    </div>
    <div class="container mx-auto flex flex-col laptop:flex-row mt-1 laptop:mt-150px md:mt-20">
      <div class="order-last laptop:order-first w-full flex laptop:w-1/2 px-5 mt-8 md:p-0 md:px-8">
        <img loading="lazy" class="w-full h-full" src="~/assets/img/how.webp" alt="Come funziona?">
      </div>
      <div class="order-first laptop:order-last w-full flex laptop:w-1/2 laptop:ml-24 p-5 laptop:px-12 md:p-0 md:px-8 flex-col laptop:justify-around">
        <div>
          <SubTitle><div v-html="$t('home.create').toString()" /></SubTitle>
          <ActionButton route="how-it-works" :value="$t('home.how').toString()" bg="highlight" fg="white" />
        </div>
      </div>
    </div>
    <div class="container mx-auto flex flex-col laptop:flex-row  mt-8 laptop:mt-150px">
      <div class="w-full flex laptop:w-5/12 laptop:ml-24 p-5 laptop:p-0 fullhd:p-5 flex-col laptop:justify-around md:px-8">
        <div class>
          <SubTitle><div v-html="$t('home.easy').toString()" /></SubTitle>
          <ActionButton route="editor" :value="$t('home.editor').toString()" bg="highlight" fg="white" />
        </div>
      </div>
      <div class="w-full flex laptop:w-1/2 p-5 md:p-0 md:px-8">
        <img loading="lazy" class="w-full h-full" src="~/assets/img/home-editor.webp" alt="Come funziona?">
      </div>
    </div>
    <div class="w-full bg-highlight bg-visualizer-medium image-discover-hero laptop:bg-visualizer bg-center bg-cover">
      <div class="container h-full mx-auto">
        <div class="flex flex-col justify-around h-full laptop:w-6/12 w-full md:w-10/12 p-5 laptop:ml-24 md:ml-8">
          <div>
            <h3 class="text-white text-4xl laptop:text-55xl md:w-3/4 md:text-4xl laptop:w-full font-bold leading-tight"
                v-html="$t('home.cta-3').toString()" />
            <div class="md:w-3/4 laptop:w-full laptop:pr-8">
              <p class="text-white laptop:text-slg mt-8 mr-24 md:mr-0" v-html="$t('home.cta-3-text').toString()" />
            </div>
            <ActionButton bg="white" fg="highlight" margin-top="mt-8" route="virtualization" :value="$t('home.find-out-more').toString()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Colors } from '@/utils/generic'

const panelLinks = usePanelLinks()

</script>

<style scoped></style>
